<template>
  <div class="container-fliud">
    <Header></Header>
<div class="faqInfo">

  <h4 class="faq" style="text-transform: lowercase; color:#97c149">{{istories.title}}</h4> 
<div class="gray">
    <div class="container">
        <div class="row"> 
  <div class="col-md-8">
     <iframe width="100%" height="415" :src="youtubeUrl" id="youtube"  frameborder="0" style="padding:10px"></iframe>
     
     <video width="700" height="310" controls id="video">
        <source :src="video"  type="video/mp4" >
        <source :src="video"  type="video/ogg" >
        <source :src="video"  type="video/webm" >
        <source :src="video"  type="video/mov" >
        <source :src="video"  type="video/wmv" >
        <source :src="video"  type="video/avi" >    
      </video>
    
    <p style="color:#707070; padding:20px;">{{istories.description}}</p>
      <div class="row">
            <div class="col-md-12" style=" margin-top:10px">{{istories.contentData}}</div>
      </div>
  </div>
   <div class="col-md-4" style="border:1px solid #E5E5E5; border-radius: 10px; padding:10px;">
   <h6 style="text-transform: lowercase; ">{{istories.title}}</h6> 
      <div class="row" style="margin-top:20px; margin-bottom:20px">
          <div class="col-md-6" style="color:#707070">Date added</div>
          <div class="col-md-6" style=" color: #98C149;">{{istories.createdAt}}</div>
      </div>
    </div>
  </div>
    </div>
</div>



</div>


    <Footer style="margin-top:80px"></Footer>
  </div>
</template>

<script>
import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl';
import appConfig from "../appConfig";
// import VuePlayerVideo from 'vue3-player-video'


export default {
   components: {
    Header,
   Footer,
  //  VuePlayerVideo
  },
    data() {
        return {
            id: this.$route.params.id,
           istories:{},
           youtubeUrl:'',
           imgUrl: appConfig.baseUrl,
           video:'',
           messageErr:''
        }
    },
    created() {
      const url = appConfig.baseUrl;
         baseUrl.get("/api/getIstoryById/" + this.id)
            .then((res)=>{
             this.istories = res.data
             if(res.data.contentType =="youtube"){
              document.getElementById("video").style.display = "none";
              this.youtubeUrl = res.data.contentData.replace("watch?v=","embed/") 
             }else{
               document.getElementById("youtube").style.display = "none"
               this.video = url + res.data.videoLink
             }
            }).catch((err)=>{
              this.messageErr = err.response.data.message
            })
    },
  
}
</script>
<style scoped>
.faqInfo{
margin-top: 180px;
}
#accordion{
padding: 100px;
}
.faq{
padding-left: 100px;
}
/* .hr{
padding-left: 100px;
} */
.gray{
background-color: #F5F7FA;
padding: 60px;
}
.container{
background-color: rbg(236, 235, 235);
}
#tweets{
padding-left: 100px;
padding-right: 100px;
padding-bottom: 20px;
margin-top: 40px;
}
.col-md-4{
border-radius: 10px;
position: relative;

}
small{
padding: 20px;
}
.twiter{
    position: absolute;
    bottom: 370px;
    right: 140px;
  }
.figure{
background-color: rgb(255, 255, 255);
border-radius: 10px;
}
.figure-caption{
padding: 20px;
}
.showMore{
text-align: center;
padding: 50px;
}
.showMore button{
border: 1px solid rgb(170, 165, 165);
padding: 10px;
widows: 200px;
border-radius: 10px;
color: rgb(170, 165, 165);
}
#img{
height: 80%;
width: 90%;
border-radius: 50px;
}
#vidoePrev{
border-radius: 10px;
}
#prev{
background-color: #fff;
border-radius: 10px;
}
.col-md-6{
padding-right: 50px;
}
</style>